







import { Component } from 'vue-property-decorator'
import myMixins from '@/mixins/common.ts'

@Component({
    components: {}
})
export default class LastPhone extends myMixins {
    phone = '134****6653'
}
